// eslint-disable-next-line no-redeclare,no-unused-vars
function convertWhereValue(key, value, where = {}, param = {}, level = 1) {
  if (level >= 10) return null;

  var valueObj = {};
  var and = [];
  var or = [];
  var orAssoc = {};
  var andAssoc = {};

  if (value == '*') return value;
  else if (str_contains(value, '.') && param.noRef) return value;
  else if (value == '0 ' || value == '0') value = 0;
  else if (value == '_' || value == '-') value = '';

  if (isPlainObject(value))
    $.each(value, function (k, v) {
      if (inArray(k, ['gte', 'lte', 'gt', 'lt', 'and', 'or', 'not'])) {
        delete value[k];
        value['$' + k] = v;
      }
    });
  if (isPlainObject(value) && value.$not) {
    valueObj.not = true;
    if (value.value) value = value.value;
    else value = value.$not;
    value = convertWhereValue(key, value, where, param, level + 1);
  }
  if (isPlainObject(value) && value.$gte)
    and.push({
      compare: '>=',
      value: convertWhereValue(key, value.$gte, where, param, level + 1),
    });
  if (isPlainObject(value) && value.$lte)
    and.push({
      compare: '<=',
      value: convertWhereValue(key, value.$lte, where, param, level + 1),
    });

  if (key == '$and' && isArray(value)) {
    $.each(value, function (i, val) {
      $.each(val, function (k, v) {
        where[k] = convertWhereValue(k, v, where, param, level + 1);
      });
    });
    return null;
  } else if (
    (key == '$or' || key == '$and' || key == '$not') &&
    isPlainObject(value)
  ) {
    if (key == '$not') valueObj.not = true;
    $.each(value, function (k, v) {
      value[k] = convertWhereValue(k, v, where, param, level + 1);
      if (key == '$or') orAssoc[k] = value[k];
      else andAssoc[k] = value[k];
    });
  } else if (isArray(value))
    $.each(value, function (i, val) {
      // value[i] = calculate(val,fields[object][key]);
      val = convertWhereValue(key, val, where, param, level + 1);
      if (val && typeof val == 'object') and.push(val);
      else value[i] = val;
    });

  if (value && isString(value)) {
    if (calculated[value]) return calculated[value];

    if (inArray(value.slice(0, 2), ['>=', '<='])) {
      valueObj.compare = value.slice(0, 2);
      value = value.slice(2);
    } else if (inArray(value.slice(0, 1), ['!', '>', '<'])) {
      if (value.slice(0, 1) == '!') valueObj.not = true;
      else valueObj.compare = value.slice(0, 1);
      value = value.slice(1);
    }

    if (str_contains(value, '&&') !== false)
      $.each(value.split('&&'), function (i, val) {
        and.push(convertWhereValue(key, val, where, param, level + 1));
      });
    else if (str_contains(value, '|') !== false)
      $.each(value.split('|'), function (i, val) {
        or.push(convertWhereValue(key, val, where, param, level + 1));
      });
    else if (str_contains(value, ',') !== false)
      $.each(value.split(','), function (i, val) {
        or.push(convertWhereValue(key, val, where, param, level + 1));
      });

    if (value.slice(0, 1) == '/' && value.slice(-1) == '/') {
      value = value.slice(1, -1);
      valueObj.regexp = true;
    }

    // reference data
    if (str_contains(value, '.') && param.row) {
      var refKey, refVal;
      [refKey, refVal] = value.split('.');
      if (refVal == 'id' && param.row[refKey + '_list']) {
        if (isArray(param.row[refKey])) {
          var values = [];
          param.row[refKey].forEach((refVal) => {
            var refId = objectSearch(param.row[refKey + '_list'], refVal);
            if (isNumeric(refId)) values.push(refId);
          });
          if (values.length) value = values;
          else param.break = true;
        } else {
          value = objectSearch(param.row[refKey + '_list'], param.row[refKey]);
          if (!isNumeric(value)) param.break = true;
        }
      } else value = getObjectValue(param.row, [refKey, refVal]);
    } else if (
      value &&
      param.row &&
      param.object &&
      fields[param.object][value]
    ) {
      value = param.row[value];
      if (!value && !param.emptyValues) value = null;
    } else if (str_starts_with(value, "'") && str_ends_with(value, "'"))
      value = value.slice(1, -1);
    else if (value) {
      param.convertWhere = true;
      value = calculate(
        value,
        getObjectValue(fields, [param.object, key]),
        param.row,
        param
      );
    }

    if (value === null) return null;
    else if (getObjectValue(fields, [param.object, key, 'decimal']))
      value = parseFloat(value);
    else if (getObjectValue(fields, [param.object, key, 'number']))
      value = parseInt(value);
    else if (
      getObjectValue(fields, [param.object, key, 'array']) &&
      value &&
      !isArray(value)
    )
      value = [value];
  }
  // else if (value && isString(value) && param.row){
  // 	if (fields[param.object][value]) value = param.row[value];
  // 	value = calculate(value,fields[param.object][key],param.row);
  // }

  if (param.simple && valueObj.compare) value = valueObj.compare + value;
  else if (param.simple && valueObj.not && !isPlainObject(value))
    value = '!' + value;
  else if (and.length) valueObj.and = and;
  else if (or.length) valueObj.or = or;
  else if (!isEmptyObject(andAssoc)) valueObj.and = andAssoc;
  else if (!isEmptyObject(orAssoc)) valueObj.or = orAssoc;

  if (where.converted && !param.simple && level < 2) {
    if (inArray(key, where.converted)) return false;
    where.converted.push(key);
  }

  if (param.object && getFieldSettings(param, key)) {
    if (!param.keys) param.keys = [];
    param.keys.push(key);
  }

  if (!isEmptyObject(valueObj) && !param.simple) {
    if (valueObj.or && key == '$or') return valueObj.or;
    else if (!valueObj.and && !valueObj.or) valueObj.value = value;
    return valueObj;
  } else return value;
}
